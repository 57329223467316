import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer>
            
        </footer>
    )
}

export default Footer
